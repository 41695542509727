.key-container {
    display: flex;
    padding-top: 5px;
    justify-content: center;  
}

.key {
    display: flex;
    align-items: center;
    padding: 0px 5px;
}

.padding-right {
    padding-right: 5px;
}
