.item-icon {
    cursor: grab;
    width: auto;
    height: auto;
}

.found-opacity {
    opacity: .5;
}

@media(max-width: 768px) {
    .item-icon {
        width: 50px;
        height: 50px;
    }
}

.content-container {
    position: relative;
    width: fit-content;
    margin: auto;
}

.new-dot-container {
    position: absolute;
    top: 5px;
    left: 5px;
}

.leaving-dot-container {
    position: absolute;
    top: 5px;
    right: 5px;
}

.item-container {
    flex-basis: 5%;
    text-align: center;
}

@media(max-width: 1333px) {
  .item-container {
    flex-basis: 5%;
  }
}
@media(max-width: 1073px) {
   .item-container {
    flex-basis: 12.5%;
  }
}
@media(max-width: 815px) {
  .item-container {
    flex-basis: 10%;
  }
}
@media(max-width: 555px) {
  .item-container {
    flex-basis: 20%;
  }
}
