.dropdown-container {
    text-align: center;
}

.sort-select {
    background-color: white;
    padding: 5px;
    margin: 2px 5px;
    font-size: 16px;
    border-radius: 5px;
}
